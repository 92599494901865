import React from "react"
import { Flex, Box, Link } from "rebass/styled-components"
import IconPlan from "../icons/IconPlan"
const ContactLink = ({ link, text, type, ...props }) => {
  return (
    <Flex alignItems="center" {...props}>
      <ContactIcon type={type} />
      <Box pl={1}>
        <Link
          variant="linktextUnderline"
          href={link}
          target={type === "lien" ? "_blank" : null}
          rel="noopener noreferrer nofollow"
        >
          {text}
        </Link>
      </Box>
    </Flex>
  )
}
export default ContactLink

const ContactIcon = ({ type }) => {
  switch (type) {
    case "fixe":
      return <IconPlan icon="Phone" size="24" color="primary" />
    case "mobile":
      return <IconPlan icon="Phone_Mobile" size="24" color="primary" />
    case "mail":
      return <IconPlan icon="Email" size="24" color="primary" />
    case "fax":
      return <IconPlan icon="Phone_Fax" size="24" color="primary" />
    case "lien":
      return <IconPlan icon="lien-url-link" size="24" color="primary" />
    default:
      return <></>
    // code block
  }
}
