import React, { useState } from "react"
import Modal from "styled-react-modal"
import { Box, Button } from "rebass/styled-components"

import IconPlan from "./icons/IconPlan"
const StyledModal = Modal.styled`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => props.opacity};
  transition: opacity ease 300ms;
`

const ModalCustomFullScreen = ({
  title,
  children,
  isOpen,
  setIsOpen,
  onClose = () => {},
  ...props
}) => {
  isOpen = isOpen || false
  title = title || ""
  const [opacity, setOpacity] = useState(0)

  const close = () => {
    setIsOpen(false)
    onClose()
  }

  function beforeClose() {
    return new Promise(resolve => {
      setOpacity(0)
      setTimeout(resolve, 200)
    })
  }

  return (
    <StyledModal
      isOpen={isOpen}
      beforeOpen={() => setOpacity(0)}
      afterOpen={() => setOpacity(1)}
      beforeClose={beforeClose}
      opacity={opacity}
      backgroundProps={{ opacity }}
      onBackgroundClick={close}
      onEscapeKeydown={close}
      {...props}
    >
      <Box px={[2, 2, 0]}>
        <Box
          bg="white"
          height="100vh"
          width="100vw"
          sx={{
            position: "relative",
            maxHeight: "100%",
            overflow: "scroll",
          }}
        >
          {children}

          <Box p={3} sx={{ position: "absolute", top: 0, right: 0, zIndex: 9 }}>
            <Button
              variant="primary"
              onClick={close}
              p={2}
              sx={{ boxShadow: "0 1px 2px rgba(0,0,0,.1)" }}
            >
              <IconPlan icon="close" size="24" sx={{ minWidth: "30px" }} />
            </Button>
          </Box>
        </Box>
      </Box>
    </StyledModal>
  )
}

export default ModalCustomFullScreen
