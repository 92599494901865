import React, { useRef } from "react"
import { Box } from "rebass/styled-components"
import { getValue, renameImage } from "../../../utils"
import RatioBox from "../RatioBox"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const ImageWrapper = ({ hasRatio, children, isMap }) => {
  return (
    <RatioBox
      ratio={
        hasRatio === "crop_new_slider" || !hasRatio
          ? isMap
            ? [190 / 300, 280 / 920, 190 / 300]
            : [190 / 300, 190 / 300, 280 / 920]
          : [280 / 920]
      }
    >
      {children}
    </RatioBox>
  )
}

const TopSlider = ({ node, isMap }) => {
  const photos = getValue(
    node,
    "field_photos_slider_new_banner",
    "Raw",
    getValue(
      node,
      "field_photos_slider_new",
      "Raw",
      getValue(node, "field_photos_slider", "Raw", [])
    )
  )

  const imageStyle = getValue(
    node,
    "field_photos_slider_new_banner",
    "Raw",
    false
  )
    ? "crop_new_slider_banner"
    : getValue(node, "field_photos_slider_new", "Raw", false)
    ? "crop_new_slider"
    : false
  const imgToken = new Date().toISOString().split("T")[0]
  const slider = useRef()
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Box
      pb={2}
      sx={{
        ".slick-dots": {
          bottom: "5px",
          width: "auto",
        },
        ".slick-dots li": {
          bg: "gray50",
          borderRadius: "20px",
          width: "10px",
          height: "10px",
          margin: "0 3px",
          "&.slick-active": {
            bg: "primary",
          },
          "button:before": {
            opacity: "0 !important",
          },
        },
      }}
    >
      <Slider ref={slider} {...settings}>
        {photos.map((photo, index) => (
          <ImageWrapper hasRatio={imageStyle} key={index} isMap={isMap}>
            <Box
              variant="bgImg"
              sx={{
                backgroundImage:
                  "url(" +
                  renameImage(photo.uri, false, imageStyle) +
                  "?v=" +
                  imgToken +
                  ")",
                backgroundSize: "contain",
              }}
              width="100%"
              height="100%"
            ></Box>
          </ImageWrapper>
        ))}
      </Slider>
    </Box>
  )
}
export default TopSlider
