import React from "react"
import { Box, Flex, Image, Text, Button } from "rebass/styled-components"
import { connect } from "react-redux"
import { Link } from "gatsby"
import HTMLReactParser from "html-react-parser"

import { getValue, renameImage } from "../utils"
import unescape from "lodash/unescape"

import Title from "../components/UI/poi/Title"
import Block from "../components/UI/poi/Block"
import Hours from "../components/UI/poi/Hours"
import Address from "../components/UI/poi/Address"
import Carte from "../components/UI/poi/Carte"
import Photos from "../components/UI/poi/Photos"
import Videos from "../components/UI/poi/Videos"
import Files from "../components/UI/poi/Files"
import Contact from "../components/UI/poi/Contact"
import Socials from "../components/UI/poi/Socials"
import Equipe from "../components/UI/poi/Equipe"
import Actions from "../components/UI/poi/Actions"
import SquareBox from "../components/UI/SquareBox"
import TopSlider from "../components/UI/poi/TopSlider"
import LongText from "../components/UI/poi/LongText"
import BadgeOffre from "../components/UI/poi/BadgeOffre"
import Loader from "../components/UI/Loader"
import Categorie from "../components/UI/poi/Categorie"
import Marques from "../components/UI/poi/Marques"
import IconPlan from "../components/UI/icons/IconPlan"
import Presentation from "../components/UI/poi/Presentation"
import Payments from "../components/UI/poi/Payments"
import Private from "../components/UI/poi/Private"
import Suggestion from "../components/UI/poi/Suggestion"

const Poi = ({ node, loading, isMap, items, lastListPath }) => {
  isMap = isMap || false
  loading = loading || false
  const title = unescape(node.title).replace("&#039;", "'")
  const leftCol = isMap ? [1] : [1, 1, 3 / 4]
  const rightCol = isMap ? [1] : [1, 1, 1 / 4]
  const activite = getValue(node, "field_activites", "value", false)
  const slogan = getValue(node, "field_slogan_client", "value", false)
  if (loading) {
    return <Loader message="Chargement en cours" />
  }
  return (
    <>
      {isMap && (
        <Box py={2}>
          <Flex>
            <Box width={[1 / 2]} pr={3}>
              {lastListPath && items.length > 1 && (
                <Link to={lastListPath} state={{ isMap: isMap }}>
                  <Button variant="topAction" as="div" width={[1]}>
                    <Flex alignItems="center" justifyContent="flex-start">
                      <IconPlan icon="arrow_left" size="24" color="primary" />
                      <Text pl={2}>Retour aux résultats</Text>
                    </Flex>
                  </Button>
                </Link>
              )}
            </Box>
            <Box width={[1 / 2]} pl={3}>
              <Link to={node.path.path}>
                <Button variant="topAction" as="div" width={[1]}>
                  <Flex alignItems="center" justifyContent="flex-end">
                    <Text pr={2}>Afficher dans l'annuaire</Text>
                    <IconPlan icon="annuaire" size="24" color="primary" />
                  </Flex>
                </Button>
              </Link>
            </Box>
          </Flex>
        </Box>
      )}
      <Box p={0}>
        <TopSlider node={node} isMap={isMap} />
        <Box>
          <Flex flexWrap="wrap">
            <Flex
              pr={isMap ? 0 : [0, 0, 0, 5]}
              width={isMap ? [1] : [1, 1, 1, 3 / 4]}
              pb={2}
            >
              <Box
                minWidth={[
                  "75px",
                  isMap ? "100px" : "210px",
                  isMap ? "100px" : "210px",
                  isMap ? "100px" : "210px",
                  isMap ? "150px" : "210px",
                ]}
              >
                <SquareBox>
                  {getValue(
                    node,
                    "field_logo_client",
                    "uri",
                    "/no-image.jpg"
                  ) && (
                    <Image
                      width="100%"
                      src={
                        renameImage(
                          getValue(
                            node,
                            "field_logo_client",
                            "uri",
                            "/no-image.jpg"
                          )
                        ) +
                        "?vid=" +
                        node.vid
                      }
                      alt={"logo de " + title}
                    />
                  )}
                </SquareBox>
              </Box>
              <Box pl={5} width={[1]}>
                <Title title={title} />
                <Flex
                  alignItems={isMap ? "start" : "center"}
                  my={2}
                  flexDirection={isMap ? ["row", "column"] : "row"}
                >
                  <Box
                    width={isMap ? ["auto"] : "auto"}
                    sx={{ minWidth: "unset" }}
                  >
                    <BadgeOffre
                      offre={getValue(
                        node,
                        "field_offre_choisie",
                        "nid.nid",
                        "48"
                      )}
                    />
                  </Box>
                  <Categorie
                    pt={isMap ? [0, 2] : 0}
                    name={getValue(node, "field_categories", "tid.name")}
                    ml={isMap ? [2, 0] : 2}
                    width={isMap ? [1] : "auto"}
                  />
                </Flex>
                <Box display={isMap ? "none" : ["none", "block"]}>
                  {slogan && (
                    <Text pb={2} fontWeight="bold">
                      {unescape(slogan).replace("&#039;", "'")}
                    </Text>
                  )}
                  {activite &&
                    getValue(node, "field_offre_choisie", "nid.nid", "48") !==
                      "48" && <LongText text={HTMLReactParser(activite)} />}
                </Box>
              </Box>
            </Flex>
            <Box display={isMap ? "block" : ["block", "none"]} width="100%">
              {slogan && (
                <Text pb={2} fontWeight="bold">
                  {unescape(slogan).replace("&#039;", "'")}
                </Text>
              )}
              {activite &&
                getValue(node, "field_offre_choisie", "nid.nid", "48") !==
                  "48" && <LongText text={HTMLReactParser(activite)} />}
            </Box>
            <Box
              pt={isMap ? 3 : [3, 3, 0]}
              pl={isMap ? 0 : [0, 0, 0, 5]}
              width={isMap ? [1] : [1, 1, 1, 1 / 4]}
            >
              <Actions isMap={isMap} node={node} />
            </Box>
          </Flex>
          <Flex flexWrap="wrap">
            <Box pr={isMap ? 0 : [0, 0, 5]} width={leftCol}>
              <Block title={"Coordonnées de " + title}>
                <Address node={node} />
                <Contact node={node} />
                <Socials node={node} display="block" id="social2" />
              </Block>
            </Box>
            <Box pl={isMap ? 0 : [0, 0, 5]} width={rightCol}>
              <Hours node={node} />
              <Payments node={node} />
            </Box>
          </Flex>
        </Box>
        <Equipe node={node} isMap={isMap} />
        <Marques node={node} isMap={isMap} />
        <Presentation node={node} isMap={isMap} />
        {!isMap && <Carte node={node} />}
        <Photos node={node} isMap={isMap} />
        <Videos node={node} isMap={isMap} />
        <Files node={node} isMap={isMap} />
        <Suggestion node={node} isMap={isMap} />
        <Private node={node} />
      </Box>
    </>
  )
}
const mapStateToProps = state => ({
  lastListPath: state.solr.lastListPath,
  items: state.solr.items,
  // isMap: state.global.isMap,
})
export default connect(mapStateToProps)(Poi)
