import React from "react"
import { Box, Text, Heading, Flex, Button } from 'rebass/styled-components'
import { connect } from "react-redux";
import IconPlan from "../UI/icons/IconPlan";
import { Link } from "gatsby"
import moment from "moment"
import 'moment/locale/fr';

const Step2 = ({ node, rdv, setIsOpen, user }) => {

  if (node) {
    return (
      <Box textAlign="center">
        <Box textAlign="center">
          <IconPlan icon="calendar_fill" size="24" color="primary" />
          <Heading as="h1" variant="h3" color="primary" pb={0}>{moment(rdv.date, 'X').format('dddd DD MMMM YYYY')}</Heading>
          <Heading pb={4} as="h1" variant="h3" color="primary">à {moment(rdv.date, 'X').format('HH [h] mm')}</Heading>
          <Text fontWeight="bold" color="primary" pb={2}>Bravo !</Text>
          <Heading pb={4} as="h1" variant="h3">Votre rendez-vous a bien été plannifié !</Heading>
        </Box>
        <Box py={4}>
          <Text>Vous allez recevoir un email de confirmation dans les plus brefs délais.</Text>
          <Text>Vous pouvez annuler ce rendez-vous à tout moment via la page <strong>Mon compte</strong> ou en <Link to="/mon-compte"><Text as="span" color="primary">cliquant ici</Text></Link>.</Text>
        </Box>
        {user && (
          <Flex justifyContent="center">
            <Button variant="primaryGradiantBig" width={["auto", "auto", 1 / 3]} onClick={() => (setIsOpen(false))}>Fermer cette fenêtre</Button>
          </Flex>
        )}

      </Box>
    )
  }
  return (
    <>
    </>
  )

}
const mapStateToProps = state => ({
  user: state.currentUser.user,
});
export default connect(mapStateToProps)(Step2);