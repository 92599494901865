import React from "react"
import { Text } from "rebass/styled-components"
import Teaser from "../Teaser"
const LongText = ({ text, ...props }) => {
  if (text.length > 600) {
    return (
      <Teaser>
        <Text {...props}>{text}</Text>
      </Teaser>
    )
  } else {
    return <Text {...props}>{text}</Text>
  }
}
export default LongText
