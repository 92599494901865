import React from "react"
import { withTheme } from "styled-components"
import { Box, Button, Text, Flex } from "rebass/styled-components"
import LazyLoad from "react-lazyload"
import {
  getIconName,
  getColorName,
  getValue,
  getNodePath,
} from "../../../utils"
import { Link } from "gatsby"
import IconPlan from "../icons/IconPlan"
import Block from "./Block"
import loadable from "@loadable/component"

const Map = loadable(() => import("../Map"))
const Carte = ({ node, theme }) => {
  if (node.field_position.und === null) {
    return <></>
  }
  const markers = [
    {
      center: [node.field_position.und[0].lon, node.field_position.und[0].lat],
      color:
        theme.colors[
          getColorName(getValue(node, "field_offre_choisie", "nid.nid"), "47")
        ],
      icon: getIconName(getValue(node, "field_offre_choisie", "nid.nid"), "47"),
      nid: node.nid,
      isCurrent: true,
      title: node.title,
      type: node.type,
    },
  ]
  return (
    <Block title={"Localisation de " + node.title}>
      <LazyLoad height={300}>
        <Box
          height={300}
          sx={{
            position: "relative",
            ".mapboxgl-ctrl-scale": { bottom: "50px" },
          }}
        >
          <Map
            lat={parseFloat(getValue(node, "field_position", "lat"))}
            lon={parseFloat(getValue(node, "field_position", "lon"))}
            zoom={18}
            markers={markers}
            mapOptions={{
              scrollZoom: false,
              interactive: false,
            }}
          />
          <Flex
            sx={{ position: "absolute", bottom: 0, right: 0, width: "auto" }}
          >
            <Link to={getNodePath(node, true)} state={{ mapDrawerOpen: true }}>
              <Button
                m={3}
                sx={{ boxShadow: "0 1px 2px rgba(0,0,0,.1)" }}
                as="div"
              >
                <Flex alignItems="center">
                  <IconPlan icon="plan-carte" size="24" />
                  <Text pl={2}>Afficher sur le plan</Text>
                </Flex>
              </Button>
            </Link>
          </Flex>
        </Box>
      </LazyLoad>
    </Block>
  )
}

export default withTheme(Carte)
