import React from "react"
import { Box, Text, Heading, Flex, Button } from "rebass/styled-components"
import { connect } from "react-redux"
import { getValue } from "../../utils"
import Address from "../UI/poi/Address"
import IconPlan from "../UI/icons/IconPlan"
import Loader from "../UI/Loader"
import moment from "moment"
import HTMLReactParser from "html-react-parser"
import "moment/locale/fr"

moment.locale("fr")

const Step2 = ({ node, rdv, createRdv, baseNode, user }) => {
  const total = rdv.prestations.reduce((total, prestation) => {
    const price = parseFloat(prestation.field_presta_prix)
    return total + (isNaN(price) ? 0 : price)
  }, 0)
  if (node) {
    if (rdv.sending) {
      return (
        <Box py={4}>
          <Loader message="Enregistrement du rendez-vous" />
        </Box>
      )
    }
    return (
      <Box>
        <Box textAlign="center">
          <Text fontWeight="bold" color="primary" pb={2}>
            Informations importantes
          </Text>
          <Heading pb={4} as="h1" variant="h3">
            À lire avant de prendre rendez-vous
          </Heading>
        </Box>
        <Box py={4}>
          <Text fontWeight="bold">Tarifs de prestation</Text>
          <Box my={2} height="2px" bg="gray10" />
          <Box>
          {rdv.prestations.map((prestation, i) => (
                <React.Fragment key={i}>
                  <Flex justifyContent="space-between">
                    <Text>{prestation.field_presta_nom}</Text>
                    {prestation.field_presta_prix !== "" &&
                      prestation.field_presta_prix !== null && (
                        <Text fontWeight="bold">
                          {prestation.field_presta_prix} F
                        </Text>
                      )}
                  </Flex>
                  {prestation.field_presta_infos !== null && (
                    <Text fontSize="80%" color="primary" py={2}>
                      {HTMLReactParser(prestation.field_presta_infos)}
                    </Text>
                  )}
                </React.Fragment>
              ))}
              <Box py={4}>
                <Flex justifyContent="space-between">
                  <Text fontWeight="bold">Total : </Text>
                  <Text>{total} F</Text>
                </Flex>
              </Box>
            <Flex mx={-2} pt={2} flexWrap="wrap">
              {getValue(node, "field_rdv_info", "value", false) !== false && (
                <Box width={[1, 1, 1 / 2]} p={2}>
                  <Text fontWeight="bold">Informations complémentaires</Text>
                  <Box my={2} height="2px" bg="gray10" />
                  <Text>
                    {HTMLReactParser(
                      getValue(node, "field_rdv_info", "value", "")
                    )}
                  </Text>
                </Box>
              )}
              {getValue(node, "field_rdv_info_paiement", "value", false) !==
                false && (
                  <Box width={[1, 1, 1 / 2]} p={2}>
                    <Text fontWeight="bold">Moyens de paiement</Text>
                    <Box my={2} height="2px" bg="gray10" />
                    <Text>
                      {HTMLReactParser(
                        getValue(node, "field_rdv_info_paiement", "value", "")
                      )}
                    </Text>
                  </Box>
                )}
            </Flex>
          </Box>
          <Box my={2}>
            <Text fontWeight="bold">Date, heure et lieu du rendez-vous</Text>
            <Box my={2} height="2px" bg="gray10" />
            <Flex mx={-2} flexWrap="wrap">
              <Flex alignItems="flex-start" width={[1, 1 / 2]} p={2}>
                <IconPlan icon="calendar_fill" size="24" color="primary" />
                <Box pl={1}>
                  <Text>
                    {moment(rdv.date, "X").format("dddd DD MMMM YYYY")}
                  </Text>
                  <Text>à {moment(rdv.date, "X").format("HH [h] mm")}</Text>
                </Box>
              </Flex>
              <Box width={[1, 1 / 2]} p={2}>
                <Address node={baseNode} hideBp={true} />
              </Box>
            </Flex>
          </Box>
        </Box>
        {user && (
          <Flex justifyContent="center">
            <Button
              variant="primaryGradiantBig"
              width={["auto", "auto", 1 / 3]}
              onClick={() => createRdv()}
            >
              Confirmer
            </Button>
          </Flex>
        )}
      </Box>
    )
  }
  return <></>
}
const mapStateToProps = state => ({
  user: state.currentUser.user,
})
export default connect(mapStateToProps)(Step2)
