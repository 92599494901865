import wkt from "wkt"
import { getValue } from "../../../utils"

export const prepareGeoJson = (markers, geojsonOnly) => {
  geojsonOnly = geojsonOnly || false
  var features = markers.map(marker => {
    var geo = {
      type: "Point",
      coordinates: marker.center,
    }
    if (geojsonOnly) {
      let isEntreprise = marker.hasOwnProperty("node")
        ? marker.node.hasOwnProperty("type")
          ? marker.node.type === "les_entreprises"
          : false
        : false
      if (!isEntreprise) {
        if (getValue(marker.node, "field_position", "geom")) {
          geo = wkt.parse(getValue(marker.node, "field_position", "geom"))
        }
      }
    }

    return {
      // feature for Mapbox DC
      type: "Feature",
      geometry: geo,
      properties: marker,
    }
  })
  return {
    type: "FeatureCollection",
    features: features,
  }
}


export const prepareGeoJsonInfos = (markers) => {
  var features = markers.map(marker => {
    var geo = wkt.parse(marker.position)
    return {
      // feature for Mapbox DC
      type: "Feature",
      geometry: geo,
      properties: marker,
    }
  })
  return {
    type: "FeatureCollection",
    features: features,
  }
}