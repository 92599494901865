import React from "react"
import { Box, Flex, Text } from "rebass/styled-components"
import { getValue } from "../../../utils"
import Block from "./Block"

const Payment = ({ node, ...props }) => {
  const payments = getValue(node, "field_moyen_de_paiement", "Raw", false)
  if (payments) {
    const paymentListLabels = {
      espece: "Espèces",
      cheque: "Chèques",
      "3x": "En 3 fois",
      "4x": "En 4 fois",
      cb: "Carte Bleue",
      virement: "Virement",
      visa: "Visa",
      mastercard: "Master Card",
      amex: "AMEX",
    }
    const paymentArr = payments
      .filter(payment => {
        return paymentListLabels[payment.value]
      })
      .map(payment => {
        return payment.value
      })
    return (
      <Block title="Moyens de paiement acceptés">
        <Flex
          maxWidth="250px"
          alignItems="flex-start"
          flexWrap="wrap"
          {...props}
          ml={-1}
        >
          {paymentArr.map((payment, i) => {
            return (
              <Box
                key={i}
                pl={1}
                width={1 / 4}
                sx={{
                  position: "relative",
                  img: { width: "100%", height: "auto" },
                  ":hover": { ".tooltip": { opacity: 1, zIndex: 9 } },
                }}
              >
                <img
                  alt={"logo " + paymentListLabels[payment]}
                  src={`/payments/${payment}.svg?new`}
                />
                <Flex
                  justifyContent="center"
                  className="tooltip"
                  ml={1}
                  sx={{
                    position: "absolute",
                    width: "max-content",
                    minWidth: "100%",
                    transition: "all 200ms ease",
                    top: "100%",
                    left: "50%",
                    background: "#fff",
                    transform: "translate(-50%)",
                    borderRadius: "5px",
                    boxShadow: "tooltip",
                    opacity: 0,
                    ":before": {
                      display: "inline-block",
                      content: "''",
                      position: "absolute",
                      top: "-8px",
                      left: "50%",
                      marginLeft: "-9px",
                      height: 0,
                      width: 0,
                      borderRight: "7px solid transparent",
                      borderBottom: "8px solid #fff",
                      borderLeft: "8px solid transparent",
                    },
                  }}
                >
                  <Text p={4} fontSize={1}>
                    {paymentListLabels[payment]}
                  </Text>
                </Flex>
              </Box>
            )
          })}
        </Flex>
      </Block>
    )
  }
  return <></>
}
export default Payment
