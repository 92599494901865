import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";

import { renameImage } from "../../../utils"
import { Box, Image, Button, Flex } from 'rebass/styled-components'
import IconPlan from "../icons/IconPlan";
const formatTitle = (photo) => {
  photo = photo || false;
  return photo ? (photo.alt.replace(" ", '') === "" ? photo.title : photo.alt) : "";
}

export default ({ images, slideIndex, node, ...props }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const slider = useRef();
  const slider2 = useRef();
  useEffect(() => {
    if (nav1 === null && slider !== null) {
      setNav1(slider.current)
    }
    if (nav2 === null && slider2 !== null) {
      setNav2(slider2.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slider, slider2])
  useEffect(() => {
    if (slideIndex) {
      props.onTitleChange(formatTitle(images[slideIndex]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideIndex])
  const next = () => (slider.current.slickNext())
  const previous = () => (slider.current.slickPrev())

  const settings = {
    dots: false,
    autoplaySpeed: 2000,
    arrows: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: images.length > 6 ? 6 : images.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: images.length > 3 ? 3 : images.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const iconStyle = { size: 24 };

  return (
    <Box>
      <Flex alignContent="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Button variant="primary" onClick={previous} p={2} >
            <IconPlan icon="arrow_left" {...iconStyle} />
          </Button>
        </Flex>
        <Box width={['80%', '80%', '90%']} px={1}>
          <Slider
            asNavFor={nav2}
            ref={slider}
            initialSlide={slideIndex}
            beforeChange={(current, next) => props.onTitleChange(formatTitle(images[next]))}
            arrows={false}
          >
            {images.map((photo, index) => (
              <Box key={"photo-" + index} textAlign='center'>
                <Image width="100%" alt={formatTitle(photo)} title={photo.title} src={renameImage(photo.uri) + '?vid=' + node.vid} />
              </Box>
            )
            )}
          </Slider>
        </Box>
        <Flex alignItems="center">
          <Button variant="primary" onClick={next} p={2} >
            <IconPlan icon="arrow_right" {...iconStyle} />
          </Button>
        </Flex>
      </Flex>
      <Box py={2} width={['80%', '80%', '90%']} m="0 auto" px={1}>
        <Box mx={-2}>
          <Slider
            asNavFor={nav1}
            ref={slider2}
            initialSlide={slideIndex}
            slidesToShow={3}
            swipeToSlide={true}
            focusOnSelect={true}
            {...settings}
          >
            {images.map((photo, index) => (
              <Button variant="ninja" px={2} key={"photo-" + index} textAlign='center' >
                <Image m="0 auto" alt={formatTitle(photo)} title={photo.title} src={renameImage(photo.uri, false, "photo_preview_fiche_entreprise") + '?vid=' + node.vid} />
              </Button>
            )
            )}
          </Slider>
        </Box>
      </Box>
    </Box>
  );
}
