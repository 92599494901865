import React, { useEffect } from "react"
import { Box, Text, Heading, Flex, Button } from "rebass/styled-components"
import User from "../User"
import { connect } from "react-redux"
import { getValue } from "../../utils"

const Step2 = ({ node, setStep, step, user }) => {
  useEffect(() => {
    if (user) {
      if (
        getValue(user, "field_phone", "number", "") !== "" &&
        parseInt(getValue(user, "field_phone_validation", "value", 0)) > 0
      ) {
        setStep(step + 1)
      }
      //
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
  if (node) {
    const title = node.title.replace("&#039;", "'")
    return (
      <Box>
        <Box textAlign="center">
          <Text fontWeight="bold" color="primary" pb={2}>
            Confirmer votre rendez-vous avec
          </Text>
          <Heading pb={4} as="h1" variant="h3">
            {title}
          </Heading>
        </Box>
        <Box py={4}>
          <User autoLoggin={true} />
        </Box>
        {user && (
          <>
            {getValue(user, "field_phone", "number", "") !== "" &&
              parseInt(getValue(user, "field_phone_validation", "value", 0)) >
                0 && (
                <Flex justifyContent="center">
                  <Button
                    variant="primaryGradiantBig"
                    width={1 / 3}
                    onClick={() => setStep(step + 1)}
                  >
                    Continuer
                  </Button>
                </Flex>
              )}
          </>
        )}
      </Box>
    )
  }
  return <></>
}
const mapStateToProps = state => ({
  user: state.currentUser.user,
})
export default connect(mapStateToProps)(Step2)
