import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import { setCurrentNode } from "../redux/actions/currentNodeActions"
import { setIsMap } from "../redux/actions/globalAction"
import { getValue, createCategorieBreadCrumb, renameImage } from "../utils"
import Page from "./Page"
import Poi from "./poi"
import { getFavoris } from "../redux/actions/currentUserActions"

const NodeTemplate = ({
  data,
  dispatch,
  isMap,
  location,
  pageContext,
  user,
  ...props
}) => {
  const { lesEntreprisesJson: node } = data
  var crumbs = []
  const titleParts = [node.title]
  const categorieName = getValue(node, "field_categories", "tid.name", "")
  titleParts.push(categorieName)
  if (node.field_categories.und) {
    crumbs = createCategorieBreadCrumb(node.field_categories.und[0].tid)
  }
  const villeName = getValue(node, "field_nom_ville", "nid.title", "")
  titleParts.push(villeName)
  const code_offre = getValue(node, "field_offre_choisie", "nid.nid", false)
  const descriptions = {
    desc48: `Retrouvez facilement le numéro de téléphone et l'adresse de ${node.title}, à ${villeName} sur l'annuaire Plan.nc.`,
    desc180: `Retrouvez les horaires et tous les services de ${node.title}, à ${villeName}. Trouvez les numéros de téléphone et adresses des entreprises sur Plan.nc.`,
    desc47:
      parseInt(getValue(node, "field_rendez_vous", "value", 0)) === 1
        ? `Prenez RDV chez ${node.title}, à ${villeName}. ${categorieName}`
        : `Retrouvez les horaires et tous les services de ${node.title}, à ${villeName}. ${categorieName}`,
  }
  crumbs.push({
    path: node.path.path,
    title: node.title,
  })
  useEffect(() => {
    dispatch(setCurrentNode(node))
    if (user) {
      dispatch(getFavoris())
    }
    if (isMap !== pageContext.isMap) {
      dispatch(setIsMap(pageContext.isMap))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // const params = new URLSearchParams(location.search)
  // const showNav = params.get("nav") === "yes"

  // const showNav = false
  return (
    <Page
      crumbs={crumbs}
      title={titleParts.join(" | ")}
      location={location}
      description={descriptions["desc" + code_offre]}
      keywords={getValue(node, "field_mots_cles", "value", "")}
      image={renameImage(
        getValue(node, "field_logo_client", "uri", "/og-image.png")
      )}
      forceMap={pageContext.isMap}
    >
      <Poi node={node} isMap={pageContext.isMap} />
    </Page>
  )
}
const mapStateToProps = state => ({
  user: state.currentUser.user,
})
export default connect(mapStateToProps)(NodeTemplate)

export const nodeQuery = graphql`
         query OfflineNodeTemplate($nid: String!) {
           lesEntreprisesJson(nid: { eq: $nid }) {
             vid
             type
             field_champ_photo {
               und {
                 uri
                 title
                 alt
               }
             }
             field_pdf1 {
               und {
                 uri
                 filename
               }
             }
             field_active_opening_hours {
               und {
                 value
               }
             }
             field_opening_hours {
               und {
                 day
                 endhours
                 starthours
               }
             }
             field_horaire_ouverture {
               und {
                 value
               }
             }
             name
             nid
             field_offre_choisie {
               und {
                 nid {
                   nid
                 }
               }
             }
             field_photo_devanture {
               und {
                 uri
                 title
                 alt
               }
             }
             field_quartier {
               und {
                 nid {
                   title
                 }
               }
             }
             field_slogan_client {
               und {
                 value
               }
             }
             field_rendez_vous {
               und {
                 value
               }
             }
             title
             field_video_channel {
               und {
                 video_url
               }
             }
             field_nom_ville {
               und {
                 nid {
                   title
                 }
               }
             }
             field_site_internet {
               und {
                 title
                 url
               }
             }
             field_mail_1 {
               und {
                 email
               }
             }
             field_mail_2 {
               und {
                 email
               }
             }
             field_mail_3 {
               und {
                 email
               }
             }
             field_photos_slider {
               und {
                 uri
                 title
                 alt
               }
             }
             field_photos_slider_new {
               und {
                 uri
                 title
                 alt
               }
             }
             field_photos_slider_new_banner {
               und {
                 uri
                 title
                 alt
               }
             }
             field_poste_fonction_1 {
               und {
                 value
               }
             }
             field_poste_fonction_photo_1 {
               und {
                 uri
                 title
                 alt
               }
             }
             field_poste_fonction_photo_2 {
               und {
                 uri
                 title
                 alt
               }
             }
             field_poste_fonction_photo_3 {
               und {
                 uri
                 title
                 alt
               }
             }
             field_poste_fonction_2 {
               und {
                 value
               }
             }
             field_poste_fonction_3 {
               und {
                 value
               }
             }
             field_poste_fonction_nom_1 {
               und {
                 value
               }
             }
             field_telephone_portable {
               und {
                 number
                 country_codes
               }
             }
             field_telephone_standard {
               und {
                 number
                 country_codes
               }
             }
             field_adresse_email {
               und {
                 email
               }
             }
             field_boite_postale {
               und {
                 value
               }
             }
             field_categories {
               und {
                 tid {
                   name
                   tid
                   url
                   parentCategorie {
                     name
                     url
                     parentCategorie {
                       name
                       url
                       parentCategorie {
                         name
                         url
                       }
                     }
                   }
                 }
               }
             }
             field_code_postal {
               und {
                 value
               }
             }
             field_compl_ment_adresse_physiqu {
               und {
                 value
               }
             }
             field_complement_bp {
               und {
                 value
               }
             }
             field_facebook {
               und {
                 value
               }
             }
             field_gsm_1 {
               und {
                 country_codes
                 number
               }
             }
             field_gsm_2 {
               und {
                 country_codes
                 number
               }
             }
             field_gsm_3 {
               und {
                 country_codes
                 number
               }
             }
             field_horaire_info {
               und {
                 value
               }
             }
             field_logo_client {
               und {
                 uri
                 title
                 alt
               }
             }
             field_marques {
               und {
                 value
               }
             }
             field_meta_description {
               und {
                 value
               }
             }
             field_mots_cles {
               und {
                 value
               }
             }
             field_numero_de_fax {
               und {
                 country_codes
                 number
               }
             }
             field_numero_rue {
               und {
                 value
               }
             }
             field_prefixe_voie {
               und {
                 value
               }
             }
             field_reference_rue {
               und {
                 nid {
                   title
                 }
               }
             }
             field_tel_poste1 {
               und {
                 country_codes
                 number
               }
             }
             field_tel_poste2 {
               und {
                 country_codes
                 number
               }
             }
             field_tel_poste3 {
               und {
                 country_codes
                 number
               }
             }
             path {
               path
               nid
             }
             field_activites {
               und {
                 value
               }
             }
             field_position {
               und {
                 lat
                 lon
               }
             }
             field_instagram {
               und {
                 value
               }
             }
             field_youtube {
               und {
                 value
               }
             }
             field_linkedin {
               und {
                 value
               }
             }
             field_tiktok {
               und {
                 value
               }
             }
             field_rdv_moyen_de_paiement {
               und {
                 value
               }
             }
             field_moyen_de_paiement {
               und {
                 value
               }
             }
             field_informations_generales {
               und {
                 value
                 value
               }
             }
           }
         }
       `
