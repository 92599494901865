import React from "react"
import { Heading, Box, Text, Flex } from "rebass/styled-components"
import Block from "./Block"
import { connect } from "react-redux"
import { getValue } from "../../../utils"
import IconPlan from "../icons/IconPlan"
const Private = ({ node, user }) => {
  if (user) {
    if (user.roles.hasOwnProperty("13")) {
      const categories = getValue(node, "field_categories", "Raw", [])
      return (
        <Box>
          <Block title={`Infos privées de ${node.title}`}>
            <Box py={2}>
              <Heading as="h3" variant="h3">
                Catégories
              </Heading>
              {categories.map(categorie => (
                <Flex key={"categorie-" + categorie.tid.tid}>
                  {categorie.tid.parentCategorie && (
                    <>
                      <Text>{categorie.tid.parentCategorie.name}</Text>
                      <IconPlan mx={1} icon="arrow_right" size={14} />
                    </>
                  )}
                  <Text>{categorie.tid.name}</Text>
                </Flex>
              ))}
              <Text>{getValue(node, "field_categories", "raw", "")}</Text>
            </Box>
            <Box py={2}>
              <Heading as="h3" variant="h3">
                Mots clés
              </Heading>
              <Text>{getValue(node, "field_mots_cles", "value", "")}</Text>
            </Box>
          </Block>
        </Box>
      )
    }
  }
  return null
}
const mapStateToProps = state => ({
  user: state.currentUser.user,
})
export default connect(mapStateToProps)(Private)
