import React from "react"
import { Box } from "rebass/styled-components"

const Badge = ({ children, ...props }) => {
  return (
    <Box variant="badge.gradiantPurple" {...props}>
      {children}
    </Box>
  )
}
export default Badge
