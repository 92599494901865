import React from "react"
import { Box, Text, Heading, Flex, Button } from "rebass/styled-components"
import { getValue } from "../../utils"
import HTMLReactParser from "html-react-parser"

const Step2 = ({ node, rdv, setStep, step }) => {
  const total = rdv.prestations.reduce((total, prestation) => {
    const price = parseFloat(prestation.field_presta_prix)
    return total + (isNaN(price) ? 0 : price)
  }, 0)
  if (node) {
    const title = node.title.replace("&#039;", "'")
    return (
      <Box>
        <Box textAlign="center">
          <Text fontWeight="bold" color="primary" pb={2}>
            Prestations de
          </Text>
          <Heading pb={4} as="h1" variant="h3">
            {title}
          </Heading>
        </Box>
        <Box py={4}>
          <Text fontWeight="bold">Tarifs de prestation</Text>
          <Box my={2} height="2px" bg="gray10" />
          <Box>
            <React.Fragment>
              {rdv.prestations.map((prestation, i) => (
                <React.Fragment key={i}>
                  <Flex justifyContent="space-between">
                    <Text>{prestation.field_presta_nom}</Text>
                    {prestation.field_presta_prix !== "" &&
                      prestation.field_presta_prix !== null && (
                        <Text fontWeight="bold">
                          {prestation.field_presta_prix} F
                        </Text>
                      )}
                  </Flex>
                  {prestation.field_presta_infos !== null && (
                    <Text fontSize="80%" color="primary" py={2}>
                      {HTMLReactParser(prestation.field_presta_infos)}
                    </Text>
                  )}
                </React.Fragment>
              ))}
              <Box py={4}>
                <Flex justifyContent="space-between">
                  <Text fontWeight="bold">Total : </Text>
                  <Text>{total} F</Text>
                </Flex>
              </Box>
              <Flex mx={-2} pt={2} flexWrap="wrap">
                {getValue(node, "field_rdv_info", "value", false) !== false && (
                  <Box width={[1, 1, 1 / 2]} p={2}>
                    <Text fontWeight="bold">Informations complémentaires</Text>
                    <Box my={2} height="2px" bg="gray10" />
                    <Text>
                      {HTMLReactParser(
                        getValue(node, "field_rdv_info", "value", "")
                      )}
                    </Text>
                  </Box>
                )}
                {getValue(node, "field_rdv_info_paiement", "value", false) !==
                  false && (
                  <Box width={[1, 1, 1 / 2]} p={2}>
                    <Text fontWeight="bold">Moyens de paiement</Text>
                    <Box my={2} height="2px" bg="gray10" />
                    <Text>
                      {HTMLReactParser(
                        getValue(node, "field_rdv_info_paiement", "value", "")
                      )}
                    </Text>
                  </Box>
                )}
              </Flex>
            </React.Fragment>
          </Box>
        </Box>
        <Flex justifyContent="center">
          <Button
            variant="primaryGradiantBig"
            width={["auto", "auto", 1 / 3]}
            onClick={() => setStep(step + 1)}
          >
            Continuer
          </Button>
        </Flex>
      </Box>
    )
  }
  return <></>
}

export default Step2
