import React from "react"
import { Box, Image, Text, Flex } from "rebass/styled-components"
import { getValue, renameImage } from "../../../utils"
import LazyLoad from "react-lazyload"
import Block from "./Block"
import ContactLink from "./ContactLink"
import SquareBox from "../SquareBox"

const Equipe = ({ node, isMap }) => {
  var i
  const postes = []
  for (i = 1; i <= 3; i++) {
    var email = getValue(node, "field_mail_" + i, "email", false)
    postes.push({
      fonction: getValue(node, "field_poste_fonction_" + i, "value", false),
      nom: getValue(node, "field_poste_fonction_nom_" + i, "value", false),
      photo: getValue(node, "field_poste_fonction_photo_" + i, "uri", false),
      gsm: getValue(node, "field_gsm_" + i, "Phone", false),
      phone: getValue(node, "field_tel_poste" + i, "Phone", false),
      mail: !email ? false : { link: "mailto:" + email, text: email },
    })
  }
  if (
    postes.filter(
      poste =>
        poste.nom || poste.fonction || poste.gsm || poste.phone || poste.mail
    ).length === 0
  ) {
    return <></>
  }
  return (
    <Block title={"Équipe de " + node.title}>
      <Flex flexWrap="wrap">
        {postes
          .filter(
            poste =>
              poste.nom ||
              poste.fonction ||
              poste.gsm ||
              poste.phone ||
              poste.mail
          )
          .map((poste, index) => (
            <Box
              width={isMap ? 1 : [1, 1, 1 / 3]}
              key={"poste-" + index}
              pb={isMap ? 4 : [4, 0]}
            >
              <Flex flexDirection={["row", "row", "column"]}>
                {poste.photo && (
                  <Box width={[1 / 3]} pb={2}>
                    <SquareBox>
                      <LazyLoad overflow={isMap}>
                        <Image
                          src={
                            renameImage(poste.photo, false, "square_400") +
                            "?vid=" +
                            node.vid
                          }
                          alt={poste.nom ? poste.nom : "Profil"}
                        />
                      </LazyLoad>
                    </SquareBox>
                  </Box>
                )}

                <Box
                  width={!poste.photo ? [1] : [2 / 3, 2 / 3, 1]}
                  pl={!poste.photo ? [0] : [2, 2, 0]}
                  pr={!poste.photo ? [0] : [0, 0, 2]}
                >
                  {poste.nom && (
                    <Text fontWeight="bold" mb={2}>
                      {poste.nom}
                    </Text>
                  )}
                  {poste.fonction && <Text mb={2}>{poste.fonction}</Text>}
                  {poste.phone && (
                    <ContactLink mb={2} type="fixe" {...poste.phone} />
                  )}
                  {poste.gsm && (
                    <ContactLink mb={2} type="mobile" {...poste.gsm} />
                  )}
                  {poste.mail && (
                    <ContactLink mb={2} type="mail" {...poste.mail} />
                  )}
                </Box>
              </Flex>
            </Box>
          ))}
      </Flex>
    </Block>
  )
}
export default Equipe
