import React from "react"
import { Flex, Text } from "rebass/styled-components"
import IconPlan from "../icons/IconPlan"
const OpenLabel = ({ isOpen, label, hideIcon, ...props }) => {
  label = label || ""
  hideIcon = hideIcon || false
  if (label === "") {
    label = isOpen ? "Actuellement ouvert" : "Actuellement fermé"
  }
  return (
    <Flex alignItems="center" color={isOpen ? "primary" : "warning"} {...props}>
      {!hideIcon && <IconPlan icon="clock" size="24" />}
      <Text pl={hideIcon ? 0 : 1} fontWeight="bold">
        {label}
      </Text>
    </Flex>
  )
}

export default OpenLabel
