import React from "react"
import { Box, Text, Flex, Image } from "rebass/styled-components"
import LazyLoad from "react-lazyload"
import LongText from "./LongText"
import unescape from "lodash/unescape"
import { getValue, renameImage } from "../../../utils"
import HTMLReactParser from "html-react-parser"
import Block from "./Block"
const Presentation = ({ node, isMap }) => {
  const title = unescape(node.title).replace("&#039;", "'")
  const infos_generales = getValue(
    node,
    "field_informations_generales",
    "value",
    false
  )
  const photo_devanture = getValue(node, "field_photo_devanture", "uri", false)
  const slogan = getValue(node, "field_slogan_client", "value", false)
  if (
    (!slogan && !photo_devanture && !infos_generales) ||
    parseInt(getValue(node, "field_offre_choisie", "nid.nid", "48")) !== 47
  ) {
    return <></>
  }
  return (
    <Block title={"Présentation de " + title}>
      <Text pb={2} fontWeight="bold">
        {unescape(getValue(node, "field_slogan_client", "value", "")).replace(
          "&#039;",
          "'"
        )}
      </Text>
      <Flex flexDirection={["column", "column", "row"]}>
        {infos_generales && (
          <Box
            width={photo_devanture ? [1, 1, 2 / 3] : [1]}
            pr={photo_devanture ? 2 : 0}
            order={[2, 2, 0]}
          >
            <LongText text={HTMLReactParser(infos_generales)} />
          </Box>
        )}
        {photo_devanture && (
          <Box width={[1, 1, 1 / 3]} order={[0]} pb={2}>
            <LazyLoad overflow={isMap}>
              <Image
                width="100%"
                src={renameImage(photo_devanture) + "?vid=" + node.vid}
                alt={"Devanture de " + title}
              />
            </LazyLoad>
          </Box>
        )}
      </Flex>
    </Block>
  )
}
export default Presentation
