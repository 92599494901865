import React from "react"
import { Box, Flex, Text } from "rebass/styled-components"
import unescape from "lodash/unescape"
import { getValue } from "../../../utils"

import IconPlan from "../icons/IconPlan"
const Address = ({ node, hideBp, hideIcon, onResult, ...props }) => {
  onResult = onResult || false
  hideBp = hideBp || false
  hideIcon = hideIcon || false
  const adresse1 = [
    getValue(node, "field_numero_rue", "value", ""),
    getValue(node, "field_prefixe_voie", "value", ""),
    getValue(node, "field_reference_rue", "nid.title", ""),
  ]
    .filter(line => line.length > 0)
    .join(" ")
  const adresse2 = [
    getValue(node, "field_quartier", "nid.title", ""),
    [
      getValue(node, "field_code_postal", "value", ""),
      getValue(node, "field_nom_ville", "nid.title", ""),
    ]
      .filter(line => line.length > 0)
      .join(" "),
  ]
    .filter(line => line.length > 0)
    .join(" - ")
  const code = getValue(node, "field_code_postal", "value", "")
  const adresseResult = [
    getValue(node, "field_quartier", "nid.title", ""),
    (code === "" ? "" : code + " ") +
      getValue(node, "field_nom_ville", "nid.title", ""),
  ]
    .filter(line => line.length > 0)
    .join(" - ")
  const bp = [
    getValue(node, "field_boite_postale", "value", ""),
    getValue(node, "field_complement_bp", "value", ""),
  ]
    .filter(line => line.length > 0)
    .join(" - ")
  const complement = getValue(
    node,
    "field_compl_ment_adresse_physiqu",
    "value",
    false
  )

  if (adresse1 === "" && adresse2 === "") {
    return <></>
  }
  if (onResult) {
    return (
      <Flex alignItems="flex-start" {...props}>
        {!hideIcon && <IconPlan icon="map-marker" size="24" color="primary" />}
        <Box pl={hideIcon ? 0 : 1}>
          <Text>{unescape(adresse1).replace("&#039;", "'")}</Text>
          <Text>{unescape(adresseResult)}</Text>
        </Box>
      </Flex>
    )
  }
  return (
    <Flex alignItems="flex-start" {...props}>
      {!hideIcon && <IconPlan icon="map-marker" size="24" color="primary" />}
      <Box pl={hideIcon ? 0 : 1}>
        <Text>{unescape(adresse1).replace("&#039;", "'")}</Text>
        {complement && <Text>{unescape(complement)}</Text>}
        <Text>{unescape(adresse2)}</Text>
        {bp && !hideBp && <Text mt={3}>BP {unescape(bp)}</Text>}
      </Box>
    </Flex>
  )
}
export default Address
