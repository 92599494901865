import React, { useEffect, useState } from "react"
import { Box, Flex, Button } from "rebass/styled-components"
import IconPlan from "../UI/icons/IconPlan"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Step4 from "./Step4"
import Step5 from "./Step5"
import { connect } from "react-redux"
import axios from "axios"

const Rdv = ({ nid, baseNode, setIsOpen, token }) => {
  const [node, setNode] = useState(false)
  const [rdv, setRdv] = useState({
    prestation: "",
    date: "",
    nid: nid,
    sent: false,
    sending: false,
    error: false,
    prestations: [],
  })
  const [step, setStep] = useState(1)
  useEffect(() => {
    axios.get(`/api/v2/rdv_infos/${nid}`).then(res => {
      if (res.data.success) {
        setNode(res.data.node)
      }
    })
  }, [nid])
  useEffect(() => {
    if (node.field_rdv_prestations) {
      setRdv({
        ...rdv,
        prestation: node.field_rdv_prestations[0],
        prestations: [node.field_rdv_prestations[0]],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node])
  useEffect(() => {
    // console.log(rdv, "rdv")
  }, [rdv])

  const createRdv = () => {
    if (!rdv.sent) {
      setRdv({ ...rdv, sending: true })
      let field_presta_prix = 0
      let field_presta_duree = 0
      let field_presta_infos = []
      let field_presta_nom = []

      rdv.prestations.forEach(prestation => {
        const price = parseFloat(prestation.field_presta_prix)
        const duree = parseFloat(prestation.field_presta_duree)

        field_presta_prix += isNaN(price) ? 0 : price
        field_presta_duree += isNaN(duree) ? 0 : duree
        field_presta_infos.push(prestation.field_presta_infos)
        field_presta_nom.push(prestation.field_presta_nom)
      })

      field_presta_prix = field_presta_prix.toString()
      field_presta_duree = field_presta_duree.toString()
      field_presta_infos = field_presta_infos.join(" + ")
      field_presta_nom = field_presta_nom.join(" + ")
      const objectPrestation = {
        field_hide_presta_duree: false,
        field_presta_duree: field_presta_duree,
        field_presta_granularite: "30",
        field_presta_prix: field_presta_prix,
        field_presta_infos: field_presta_infos,
        field_presta_nom: field_presta_nom,
      }
      axios
        .post(
          `/api/v2/save_rdv`,
          { nid: node.nid, prestation: objectPrestation, date: rdv.date },
          {
            crossdomain: true,
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": token,
            },
          }
        )
        .then(res => {
          if (res.data.success) {
            setStep(5)
            setRdv({ ...rdv, sent: true, sending: false })
          } else {
            setStep(1)
            setRdv({
              ...rdv,
              sent: false,
              date: "",
              sending: false,
              error: res.data.status,
            })
          }
        })
    } else {
      setStep(5)
    }
  }
  if (node) {
    const stepProps = {
      node: node,
      rdv: rdv,
      setRdv: setRdv,
      step: step,
      setStep: setStep,
      baseNode: baseNode,
      setIsOpen: setIsOpen,
      createRdv: createRdv,
    }
    if (step <= 5) {
      return (
        <Box pb={4}>
          {step > 1 && (
            <Button
              variant="ninja"
              p={4}
              onClick={() => setStep(step - 1)}
              sx={{ position: "absolute", top: 0, left: 0 }}
              display="flex"
              alignItems="center"
            >
              <IconPlan icon="chevron_left" size="24" />
              Retour
            </Button>
          )}
          <Flex justifyContent="center">
            <Box width={[1, 1, 3 / 4]}>
              {step === 1 && <Step1 {...stepProps} />}
              {step === 2 && <Step2 {...stepProps} />}
              {step === 3 && <Step3 {...stepProps} />}
              {step === 4 && <Step4 {...stepProps} />}
              {step === 5 && <Step5 {...stepProps} />}
            </Box>
          </Flex>
        </Box>
      )
    }
  }
  return <></>
}
const mapStateToProps = state => ({
  user: state.currentUser.user,
  token: state.currentUser.token,
})
export default connect(mapStateToProps)(Rdv)
