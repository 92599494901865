import React from "react"
import { Text } from "rebass/styled-components"

import Badge from "../Badge"

const BadgeOffre = ({ offre }) => {
  var text
  var offreNid
  switch (offre) {
    case "Gratuit":
      offreNid = 48
      break
    case "Premium":
      offreNid = 47
      break
    case "Gold":
      offreNid = 180
      break
    case "Platinum":
      offreNid = 187
      break
    default:
      offreNid = parseInt(offre)
  }
  switch (offreNid) {
    case 48:
      text = "Gratuit"
      break
    case 47:
      text = "Premium"
      break
    case 180:
      text = "Gold"
      break
    case 187:
      text = "Platinum"
      break
    default:
      text = false
  }
  if (!text) {
    return <></>
  }
  return (
    <Badge variant={"badge.offre" + offre}>
      <Text variant="caps">{text}</Text>
    </Badge>
  )
}
export default BadgeOffre
