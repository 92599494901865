import React from "react"
import { Box, Text, Flex } from "rebass/styled-components"
import { getValue } from "../../../utils"
import HTMLReactParser from "html-react-parser"
import Block from "./Block"
const Marques = ({ node, isMap }) => {
  const marquesText = getValue(node, "field_marques", "value", false)
  const marques = getValue(node, "field_marques_ref", "Raw")
  if (!marques && !marquesText) {
    return <></>
  }
  return (
    <Block title={"Marques de " + node.title}>
      <Flex flexWrap="wrap">
        {marques && (
          <>
            {marques.map((marque, index) => (
              <Box
                key={"marque-" + index}
                width={isMap ? [1 / 2] : [1 / 2, 1 / 4]}
              >
                <Text fontWeight="bold" mb={2}>
                  {marque.tid.name}
                </Text>
              </Box>
            ))}
          </>
        )}
        {!marques && marquesText && (
          <Box width={[1]}>
            <Text mb={2}>{HTMLReactParser(marquesText)}</Text>
          </Box>
        )}
      </Flex>
    </Block>
  )
}
export default Marques
