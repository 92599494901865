import React, { useState } from "react"
import { Box, Flex, Button, Text } from "rebass/styled-components"
import { motion, AnimatePresence } from "framer-motion"
import IconPlan from "./icons/IconPlan"
const Teaser = ({ children, collapsedHeight, seeMore, ...props }) => {
  collapsedHeight = collapsedHeight || 150
  seeMore = seeMore || "Voir plus"
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Box
      {...props}
      sx={{
        ".button": { opacity: isOpen ? 0 : 1 },
        ":hover": {
          ".button": { opacity: 1 },
        },
      }}
    >
      <Box sx={{ position: "relative", overflow: "hidden" }}>
        <AnimatePresence initial={false}>
          <motion.section
            key="content"
            initial="collapsed"
            animate={isOpen ? "open" : "collapsed"}
            exit="collapsed"
            variants={{
              open: { height: "auto" },
              collapsed: { height: collapsedHeight },
            }}
            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <Box>{children}</Box>
          </motion.section>
          <Box
            maxHeight="30px"
            width="100%"
            height="100%"
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: isOpen ? "transparent" : "rgb(255,255,255)",
              background: isOpen
                ? "transparent"
                : "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%)",
            }}
          ></Box>
        </AnimatePresence>
      </Box>
      <Button
        className="button"
        sx={{ transition: "opacity 300ms ease" }}
        onClick={() => setIsOpen(!isOpen)}
        variant="ninja"
        width="100%"
      >
        <Flex flexWrap="wrap" alignItems="center" justifyContent="flex-end">
          <Text fontWeight="bold" fontSize={1}>
            {isOpen ? "Voir moins" : seeMore}
          </Text>
          <IconPlan
            icon="chevron_up"
            size="24"
            color="primary"
            sx={{
              transition: "all 300ms ease",
              transform: isOpen ? "rotate(0deg)" : "rotate(180deg)",
            }}
          />
        </Flex>
      </Button>
    </Box>
  )
}

export default Teaser
